import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import * as api from '../network/api'
import router from '@/router';
import {
	decrypt,
	verification
} from '../utils/auth.js'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		token: '',
		userid: '',
		stationId: '1005012',
		userType: 'user_type_01',
		code: '',
		user: {},
		realnameornot: {},
		logininformation: {},
		img: '',
	},
	mutations: {
		SET_IMG: (state, res) => {
			state.img = res
		},
		SET_TOKEN: (state, res) => { // 保存token
			let date = {
				phone: res.res.phone,
				username: res.res.username,
				realname: res.res.realname
			}
			api.userInfo(date).then(res => {
				res.data.data.phone = decrypt(res.data.data.phone)
				res.data.data.realname = !verification(res.data.data.realname) ? decrypt(res.data.data.realname) : ''
				res.data.data.username = decrypt(res.data.data.username)
				state.user = res.data.data

				let states = JSON.stringify(state);
				localStorage.setItem("store", states == null ? '' : states);
			})

			api.getPeopleAuthenInfo({
				userid: res.res.userid
			}).then(res => {
				res.data.data.result.realname = !verification(res.data.data.result.realname) ? decrypt(res.data.data.result.realname) : ''
				res.data.data.result.cardnum = decrypt(res.data.data.result.cardnum)
				res.data.data.result.phone = decrypt(res.data.data.result.phone)
				state.realnameornot = res.data.data.result

				let states = JSON.stringify(state);
				localStorage.setItem("store", states == null ? '' : states);
			})

			state.token = res.res.accessToken
			state.userid = res.res.userid
			state.userType = decrypt(res.res.userType)
			state.logininformation = res.res

			api.getTimeStamp().then(ess => {
				let data = {
					accessToken: res.res.accessToken,
					redirectURL: '/authIndex',
					timestampe: ess.data.data.timestamp
				}
				api.getCode(data).then(err => {
					state.code = err.data.data.result.code

					if (decrypt(res.res.userType) == 'user_type_02') {
						let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data.data.result.code
						window.open(url, '_self');
					}

					let states = JSON.stringify(state);
					localStorage.setItem("store", states == null ? '' : states);
				})
			})

			if (decrypt(res.res.userType) == 'user_type_01') {
				if (res.index == 2) {
					router.push({
						path: '/registerIndex',
						query: {
							refresh: new Date().getTime()
						},
					})
				} else {
					router.push({
						path: '/h5/registerIndex',
						query: {
							refresh: new Date().getTime()
						},
					})
				}
			} else {
				if (res.index == 2) {
					router.push({
						path: '/'
					})
				} else {
					router.push({
						path: '/h5'
					})
				}
			}

		},
		EXIT_TOKEN: (state) => { // 清除缓存
			state.token = ''
			state.userid = ''
			state.userType = ''
			state.code = ''
			state.user = {}
			state.realnameornot = {}
			state.logininformation = {}
			localStorage.clear()
		},
		EXIT_STATIONID: (state, res) => { // 保存stationId
			state.stationId = res
		},
		EXIT_REALN: (state, res) => {
			api.getPeopleAuthenInfo({
				userid: state.userid
			}).then(res => {
				res.data.data.result.realname = !verification(res.data.data.result.realname) ? decrypt(res.data.data.result.realname) : ''
				res.data.data.result.cardnum = !verification(decrypt(res.data.data.result.cardnum)) ? decrypt(res.data.data.result.cardnum) : ''
				res.data.data.result.phone = !verification(decrypt(res.data.data.result.phone)) ? decrypt(res.data.data.result.phone) : ''
				state.realnameornot = res.data.data.result

				let states = JSON.stringify(state);
				localStorage.setItem("store", states == null ? '' : states);
			})
		}
	},
	actions: {},
	getters: {},
	modules: {},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage
		})
	],
});